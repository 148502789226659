import React from 'react';
import './style.scss';
import NavLink from './nav-link'

const CategoryNav = () => (
    <div className="columns is-centered is-gapless is-hidden-mobile">
        <div className="column is-10">
            <div className="navbar-menu">
                <div className="navbar-start nav-link">
                    <NavLink to="#">Product Design</NavLink>
                    <NavLink to="#">Self Help</NavLink>
                    <NavLink to="#">Business</NavLink>
                    <NavLink to="#">Frontend</NavLink>
                    <NavLink to="#">Freebies</NavLink>
                </div>
            </div>
        </div>
    </div>
);

export default CategoryNav;
