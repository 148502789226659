import React from 'react';

import './style.scss';
import Helmet from './helmet';
import Header from './header';
import FooterBlog from './footer-blog';

const Layout = ({ children }) => (
    <div>
        <Helmet />
        <Header />

        {children}

        <FooterBlog />
    </div>
);

export default Layout;
