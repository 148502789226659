import React from 'react';
import './style.scss';
import NavLink from './nav-link'

const CategoryNavMobile = () => (
    <div className="columns is-centered is-gapless is-hidden-tablet">
        <div className="column is-10">
            <div className="navbar-menu">
                <div className="nav-link">
                    <NavLink to="/blog/product-design">Product Design</NavLink>
                    <NavLink to="/blog/product-self-help">Self Help</NavLink>
                    <NavLink to="/blog/business">Business</NavLink>
                    <NavLink to="/blog/frontend">Frontend</NavLink>
                    <NavLink to="/blog/freebies">Freebies</NavLink>
                </div>
            </div>
        </div>
    </div>
);

export default CategoryNavMobile;
