import React from 'react';
import LayoutBlog from '../components/layout-blog';
import CategoryNav from '../components/category-nav';
import CategoryNavMobile from '../components/category-nav-mobile';
import PostCard from '../components/post-card';
// import Pagination from '../components/pagination';

const BlogList = ({ data }) => (
  <LayoutBlog>
    <div className="columns is-centered is-multiline">
      <div className="column is-12 category-nav">
        <div className="border-bound">
          <CategoryNav />
        </div>
        <div className="mobile-cat-section">
          <CategoryNavMobile />
        </div>
      </div>
    </div>
    <div className="columns blog-list is-centered is-multiline">
      <PostCard />
      {/* <Pagination /> */}
    </div>
  </LayoutBlog>

);

export default BlogList;
