import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"
import Img from 'gatsby-image';

const PostCard = () => (
    <StaticQuery
        query={graphql`
            query FeaturedImage {
                imageOne: file(relativePath: { eq: "sample-1.png" }) {
                    childImageSharp {
                        fluid(quality: 70) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
                imageTwo: file(relativePath: { eq: "sample-2.png" }) {
                    childImageSharp {
                        fluid(quality: 70) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
                imageThree: file(relativePath: { eq: "sample-3.png" }) {
                    childImageSharp {
                        fluid(quality: 70) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
                imageFour: file(relativePath: { eq: "sample-6.png" }) {
                    childImageSharp {
                        fluid(quality: 70) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
    `}
        render={(data) => (
            <section className="column is-10">
                <div className="columns is-centered is-multiline">
                    <div className="column is-12">
                        <div className="box">
                            <div className="columns is-centered">
                                <div className="column is-6">
                                    <Link to="#">
                                        <Img fluid={data.imageOne.childImageSharp.fluid} alt="featured image" className="featured-image" />
                                    </Link>
                                </div>
                                <div className="column is-6">
                                    <div className="featured-card">
                                        <Link to="#"><p className="post-category">Category</p></Link>
                                        <Link to="#"><h2 className="post-title">Hold tight this page is coming soon</h2></Link>
                                        <Link to="/case-studies"><p>Meanwhile, you can check case studies<span className="link-text"> here</span></p></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="column is-4 is-flex">
                        <div className="box">
                            <div className="columns is-centered">
                                <div className="column is-12">
                                    <Link to="/">
                                        <Img fluid={data.imageTwo.childImageSharp.fluid} alt="featured image" className="featured-image" />
                                    </Link>
                                    <div className="post-card">
                                        <Link to="/"><p className="post-category">Product Design</p></Link>
                                        <Link to="/"><h2 className="post-title">The Fastest Navigation Layout for a Three-Level ..</h2></Link>
                                        <Link to="/"><p>Years ago it was common practice to place a link to your sitemap in the footer navigation. Those days are over ..</p></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="column is-4 is-flex">
                        <div className="box">
                            <div className="columns is-centered">
                                <div className="column is-12">
                                    <Link to="/">
                                        <Img fluid={data.imageThree.childImageSharp.fluid} alt="featured image" className="featured-image" />
                                    </Link>
                                    <div className="post-card">
                                        <Link to="/"><p className="post-category">Product Design</p></Link>
                                        <Link to="/"><h2 className="post-title">How to Design Destructive Actions That Prevent Data Loss</h2></Link>
                                        <Link to="/"><p>Data loss is one of the greatest frustrations users can experience with computers. They not only lose their ..</p></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="column is-4 is-flex">
                        <div className="box">
                            <div className="columns is-centered">
                                <div className="column is-12">
                                    <Link to="/">
                                        <Img fluid={data.imageFour.childImageSharp.fluid} alt="featured image" className="featured-image" />
                                    </Link>
                                    <div className="post-card">
                                        <Link to="/"><p className="post-category">Product Design</p></Link>
                                        <Link to="/"><h2 className="post-title">When You Need to Show a Button’s Loading State</h2></Link>
                                        <Link to="/"><p>Buttons have more than an enabled and disabled state. They also have a loading state. The loading state isn't usually ..</p></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
            </section>
        )}
    />
)

export default PostCard